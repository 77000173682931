<template>
    <div class="sourcesMedia-container">
        <div class="sourcesMedia-content">
            <div class="sourcesMedia" @click.self.prevent="openMedia()">
                <div class="iframe-container">
                    <img v-if="sourcesMedia.includes('youtube')" :src="picture" class="picture" />
                    <!-- <iframe v-if="sourcesMedia.includes('youtube')" class="player" rel="0" :src="videoParse() + '?modestbranding=1&iv_load_policy=3&rel=0&autohide=1&showinfo=0&controls=0&modestbranding=1&rel=&loop=1&playlist=' + getYTVideoId()" frameborder="0" allow="accelerometer; encrypted-sourcesMedia; gyroscope; picture-in-picture" allowfullscreen iv_load_policy="3"></iframe> -->
                    <iframe v-else-if="sourcesMedia.includes('vimeo')" class="player" :src="videoParse() + '?title=0&byline=0&portrait=0&sidedock=0'" frameborder="0" allowfullscreen></iframe>
                    <video class="player" :src="sourcesMedia" v-else-if="sourcesMedia && !sourcesMedia.includes('vimeo')" controls></video>
                </div>
            </div>
            <!-- <div class="sourcesMedia-description" @click.self.prevent="openMedia()" v-html="descriptionSliced"></div> -->
        </div>
        <div class="title-sourcesMedia" v-html="title"></div>
    </div>
</template>

<script>
export default {
    name: 'MediaGallery',
    props: {
        title: {
            type: String,
            default: false
        },
        sourcesMedia: {
            type: String,
            default: false
        },
        description: {
            type: String,
            default: false
        }
    },
    computed: {
        descriptionSliced() {
            return this.description ? this.description.slice(0, 125) + ' ...' : ''
        },
        picture() {
            if (this.sourcesMedia.includes('youtube')) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                const match = this.sourcesMedia.match(regExp)
                var videoID = match && match[2].length === 11 ? match[2] : null
                return 'https://img.youtube.com/vi/' + videoID + '/mqdefault.jpg'
            }

            return false
        }
    },
    methods: {
        openMedia() {
            this.$popup.mediaOnboarding({ data: this.sourcesMedia, message: this.description, title: this.title })
        },
        getYTVideoId() {
            if (this.sourcesMedia.includes('youtube')) {
                var url = this.sourcesMedia
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                var match = url.match(regExp)
                if (match && match[2].length == 11) {
                    return match[2]
                } else {
                    return false
                }
            }
        },
        videoParse() {
            if (this.sourcesMedia.includes('youtube')) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                const match = this.sourcesMedia.match(regExp)
                var videoID = match && match[2].length === 11 ? match[2] : null
                return 'https://www.youtube-nocookie.com/embed/' + videoID
            } else if (this.sourcesMedia.includes('vimeo')) {
                var regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
                var match = this.sourcesMedia.match(regExp)
                return 'https://player.vimeo.com/video/' + match[3]
            }
            return ''
        }
    }
}
</script>

<style lang="scss">
.sourcesMedia-container {
    @include display-flex();
    @include flex-direction(column);
    @include border-radius(4px);
    box-shadow: 0px 0px 0px rgba(117, 115, 119, 0.1);
    background-color: $color-neutral-200;
    width: 100%;
    height: 100%;
    padding: 10px;

    .title-sourcesMedia {
        @include font-size(sm);
        font-family: $text-bold;
        padding-top: 6px;
        height: 60px;
        text-align: left;
        vertical-align: middle;
        display: flex;
        text-transform: uppercase;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        color: $color-black;
    }

    .sourcesMedia-content {
        @include display-flex();
        @include flex-direction(row);
        @include justify-content(space-between);
        width: 100%;
        position: relative;
        z-index: 2;
        cursor: pointer;

        .sourcesMedia {
            width: calc(50% - 16px);
            width: 100%;
            // height: 110px;
            background-color: transparent;
            z-index: initial;
            position: relative;
            cursor: pointer;

            .iframe-container {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: -1;

                .player {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: -1;
                }

                .picture {
                    position: relative;
                    display: block;
                    @include border-radius(4px);
                }
                &:after {
                    content: ' ';
                    @include background($image: img('youtube.svg'), $size: 60px, $position: center center);
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    content: '';
                    position: absolute;
                    opacity: 0.4;
                }
            }
            &:hover {
                .iframe-container {
                    &:after {
                        opacity: 0.75;
                    }
                }
            }
        }

        .sourcesMedia-description {
            @include font-size(sm);
            font-family: $text-light;
            width: 100%;
        }
    }
}
</style>
