<template>
    <div class="help-center-container">
        <div class="help-center-content">
            <div class="need-help" v-html="$t('help_center.need_help')"></div>
            <div class="text-container">
                <div class="text-top question" v-html="$t('help_center.go_description')"></div>
                <div class="btn-container">
                    <div class="btn-main" @click="goToHelpCenter()">
                        {{ $t('help_center.go') }}
                    </div>
                </div>
            </div>
            <div class="text-container">
                <div class="text-top chat" v-html="$t('help_center.chat_description')"></div>
                <div class="btn-container">
                    <div class="btn-main" @click="openChat()">
                        {{ $t('onboarding.open_chat') }}
                    </div>
                </div>
            </div>
            <div class="text-container">
                <div class="text-top mail" v-html="$t('help_center.email')"></div>
                <div class="btn-container"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpCenter',
    methods: {
        goToHelpCenter() {
            window.open('https://help.andyapp.io/portal/es/home', '_blank')
        },
        openChat() {
            document.getElementsByClassName('zsiq_floatmain')[0].click()

            const oAccount = this.$store.getters['loginUser/getLocalAccount']
            const oUser = this.$store.getters['loginUser/getLocalUser']
            const oRole = this.$store.getters['employee/getRoles'](oUser.rol)

            $zoho.salesiq.ready = function () {
                $zoho.salesiq.visitor.info({
                    app_version: process.env.VUE_APP_VERSION,
                    account: oAccount.account_name,
                    employee: oUser.name + ' ' + oUser.surname,
                    employee_email: oUser.email,
                    employee_rol: oRole.name + ' (Level ' + oRole.level + ')'
                })
            }
            $zoho.salesiq.ready()
        }
    }
}
</script>

<style lang="scss">
.help-center-container {
    @include border-radius(4px);
    background-color: $color-neutral-300;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 20px 0;

    .help-center-content {
        @include display-flex();

        .need-help {
            @include display-flex();
            @include flex-direction(column);
            @include align-self(center);
            @include font-size(sm);
            font-family: $text;
            width: 290px;

            b {
                color: $color-primary-800;
                font-family: $text-bold;
                @include font-size(md);
                padding-bottom: 2px;
            }
        }

        .text-container {
            @include display-flex();
            @include flex-direction(column);
            padding-left: 45px;
            width: 30%;
            height: 100%;

            .text-top {
                @include font-size(xs);
                font-family: $text;
                color: $color-black;
                padding-left: 50px;
                padding-bottom: 6px;
                height: 80%;
                min-height: 50px;
                color: $color-black;

                &.question {
                    @include background($image: img('menu_info_main.svg'), $position: left top, $size: 30px);
                }

                &.chat {
                    @include background($image: img('chat_main.svg'), $position: left top, $size: 30px);
                }
                &.mail {
                    @include background($image: img('email_main.svg'), $position: left top, $size: 30px);
                    width: 200px;
                }

                b {
                    @include font-size(xs);
                    user-select: all;
                }
            }

            .btn-container {
                padding-left: 50px;
                .btn-main {
                    @include font-size(sm);
                    @include border-radius(4px);
                    background-color: $color-secondary-500;
                    width: fit-content;
                    padding: 10px 30px;
                    color: #fff;
                    font-family: $text-bold;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        @include bgHover($color-secondary-800);
                    }
                }
            }
        }
    }
}
</style>
