<template>
    <div id="content">
        <div class="onboarding-container hide-scrollbar">
            <div class="top-content">
                <div class="title-container">
                    {{ $t('onboarding.title') }}
                </div>
                <div class="access-container" v-if="locations.length > 0">
                    <div class="access">
                        {{ $t('onboarding.access_text') }}
                    </div>
                    <div class="btn-container">
                        <div class="btn-main" @click="goToApp()">
                            {{ $t('onboarding.btn_access') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-gallery-container" v-if="Object.values(gallery).length > 0">
                <MediaGallery v-for="(sourcesMedia, index) in gallery" :key="index" :title="sourcesMedia.title" :sourcesMedia="sourcesMedia.url" :description="sourcesMedia.description"></MediaGallery>
            </div>
            <div class="footer-onboarding-container">
                <HelpCenter></HelpCenter>
            </div>
        </div>
    </div>
</template>

<script>
import HelpCenter from '@/components/layout/HelpCenter.vue'
import MediaGallery from '@/components/layout/MediaGallery.vue'
export default {
    name: 'Onboarding',
    components: {
        MediaGallery,
        HelpCenter
    },
    data() {
        return {}
    },
    computed: {
        locations() {
            var locations = this.$store.getters['location/getListTable']()
            var locationResult = []
            for (var location in locations) {
                // username ends with .demo
                if (locations[location].demo == 1) {
                    locationResult.push(locations[location])
                }
            }

            if (locationResult.length == 0) {
                locationResult.push(Object.values(locations)[0])
            }
            return locationResult
        },
        gallery() {
            return Object.values(this.$store.getters['onboarding/getGallery'])
        }
    },
    methods: {
        goToApp() {
            var token = localStorage.getItem('token')
            var user_id = JSON.parse(localStorage.getItem('user')).employee_id
            var url = process.env.VUE_APP_APP_URL + 'dbpreview/' + token + '/' + this.locations[0].id + '/' + user_id
            window.open(url)
        },
        load() {
            var self = this
            this.$overlay.loading()
            this.$store
                .dispatch('onboarding/loadGallery')
                .then((result) => {
                    self.$overlay.hide()
                })
                .catch((err) => {
                    self.$overlay.hide()
                })
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
.onboarding-container {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: calc(100% - 40px);
    padding: 30px;
    overflow: auto;

    .top-content {
        @include display-flex();
        @include justify-content(space-between);
        width: 100%;
        height: auto;
        padding-bottom: 25px;

        .title-container {
            @include font-size(lg);
            font-family: $text-bold;
            color: $color-black;
        }

        .access-container {
            @include display-flex();

            .access {
                @include background($image: img('menu_ubication_main_s50.svg'), $position: left center);
                background-size: 35px;
                background-position: left center;
                @include font-size(sm);
                padding-left: 48px;
                font-family: $text;
                color: $color-black;
                width: 260px;
                padding-right: 8px;
            }

            .btn-container {
                .btn-main {
                    @include font-size(sm);
                    @include border-radius(4px);
                    background-color: $color-secondary-500;
                    width: fit-content;
                    padding: 10px 30px;
                    color: #fff;
                    font-family: $text-bold;
                    cursor: pointer;

                    &:hover {
                        @include bgHover($color-secondary-800);
                    }
                }
            }
        }
    }

    .video-gallery-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 25px;
    }

    //  1700 px grid 4 rows

    @media (min-width: 1700px) {
        .video-gallery-container {
            grid-template-columns: repeat(6, 1fr);
        }
    }
    @media (max-width: 1300px) {
        .video-gallery-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .footer-onboarding-container {
        width: 100%;
        height: auto;
        padding-top: 30px;
        max-width: 1300px;
        align-self: center;
    }
}
</style>
